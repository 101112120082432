import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ComicReader from "../components/comic-reader"
import ComicItemsList from "../components/comic-items-list"
import _ from "lodash"
import ComicItemInfo from "../components/comic-item-info"
import SectionHeader from "../components/headers/section-header"
import PageHeader from "../components/headers/page-header"
import Wide3DHeader from "../components/headers/wide-3d-header"

class ComicIssueTemplate extends React.Component {
  render() {

    // TODO: get series/parent
    const series = this.props.data.comic
    const issue = this.props.data.markdownRemark
    const issues = this.props.data.issues.edges
    const pages = this.props.data.pages.edges
    const allSeriesPages = this.props.data.allSeriesPages.edges

    const publishedComics = this.props.data.publishedComics.edges

    // get current page position in pages
    let currentIssueIndex = _.findIndex(issues, { node: { frontmatter: { title: issue.frontmatter.title } } })

    let firstIssue = issues.length && issues[0].node

    let nextIssue = null
    if (currentIssueIndex < issues.length - 1) {
      nextIssue = issues[currentIssueIndex + 1].node
    } else {
      if (series.frontmatter.anthology && currentIssueIndex !== 0) {
        nextIssue = issues[0].node
      }
    }


    let prevIssue = null
    if (currentIssueIndex > 0) {
      prevIssue = issues[currentIssueIndex - 1].node
    } else {
      if (series.frontmatter.anthology && issues.length !== 1) {
        prevIssue = issues[issues.length - 1].node
      }
    }

    let prevPage
    if (prevIssue) {
      // get pages from previous issue
      prevPage = _.filter(allSeriesPages, { node: { frontmatter: { issue: prevIssue.frontmatter.title } } }).pop()
    }

    return (
      <Layout
        location={this.props.location}

        svg={series.frontmatter.svg.publicURL}
      >

        <SEO
          title={`${series.frontmatter.title} | ${issue.frontmatter.title}`}
          description={issue.frontmatter.description || issue.excerpt}
          ogImageUrl={issue.frontmatter.image.childImageSharp.fluid.src}
        />

        <ComicReader
          nextIssue={nextIssue}
          previousIssue={prevIssue}

          firstIssue={(firstIssue.fields.slug !== issue.fields.slug) ? firstIssue : null}
          comic={series}
          issue={issue}
          latestPage={allSeriesPages[0] && allSeriesPages[allSeriesPages.length - 1].node}
          previousPage={prevPage && prevPage.node}
          nextPage={pages[0] && pages[0].node}/>

        <ComicItemInfo html={issue.html} image={issue.frontmatter.image} title={`About ${issue.frontmatter.title}`} credits={issue.frontmatter.credits} />
        <ComicItemInfo html={series.html} image={series.frontmatter.promo || series.frontmatter.image}  title={`About ${series.frontmatter.title}`} credits={series.frontmatter.credits} link={series.fields.slug} />

        {issues.length > 1 && <Wide3DHeader>Issues in this Series</Wide3DHeader>}
        <ComicItemsList items={issues} exclude={[issue]}/>

        <Wide3DHeader>Other Comic Series</Wide3DHeader>
        <ComicItemsList items={publishedComics} exclude={[series]}/>
      </Layout>
    )
  }
}

export default ComicIssueTemplate

export const pageQuery = graphql`
    query ComicIssueTemplateQuery($slug: String!, $id: String!, $comicID: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            html
            fields { slug }
            ...ComicIssueFrontmatter
        }

        comic: markdownRemark(frontmatter: { title: { eq: $comicID }, type: { eq: "comic" } }) {
            id
            html
            fields { slug }
            ...ComicSeriesFrontmatter
        }

        pages: allMarkdownRemark(sort: {order: ASC, fields: frontmatter___seriesPage}, filter: {frontmatter: {type: {eq: "comic-page"},  comic: {eq: $comicID},  issue: {eq: $id} }}) {
            edges {
                node {
                    fields {
                        slug
                    }
                    ...ComicPageOptimizedFrontmatter
                }
            }
        }

        allSeriesPages: allMarkdownRemark(sort: {order: ASC, fields: frontmatter___seriesPage}, filter: {frontmatter: {type: {eq: "comic-page"},  comic: {eq: $comicID} }}) {
            edges {
                node {
                    fields {
                        slug
                    }
                    ...ComicPageOptimizedFrontmatter
                }
            }
        }

        issues: allMarkdownRemark(filter: {frontmatter: {type: {eq: "comic-issue"}, comic: {eq: $comicID}}}, sort: {fields: frontmatter___date, order: ASC}) {
            edges {
                node {
                    id
                    fields {
                        slug
                    }
                    ...ComicIssueFrontmatter
                }
            }
        }

        # Published Comics Series
        publishedComics: allMarkdownRemark(filter: {frontmatter: {type: {eq: "comic"}, published: {ne: false}}}) {
            edges {
                node {
                    id
                    fields {slug }
                    ...ComicSeriesFrontmatter
                }
            }
        }
    }
`
